import { FirstPage } from '@styled-icons/material';
import { Icon, IconProps } from './Icon';

export function FirstPageIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <FirstPage />
    </Icon>
  );
}
