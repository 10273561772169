import { SortDown } from '@styled-icons/boxicons-regular';
import { Icon, IconProps } from './Icon';

export function SortDownIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <SortDown />
    </Icon>
  );
}
